import React, { useContext, useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
import { Link } from 'react-scroll';
import Typewriter from 'typewriter-effect';
import PortfolioContext from '../../context/context';

import dp from '../../images/dp.webp';

const Header = () => {
  const { hero } = useContext(PortfolioContext);
  const { title, name } = hero;

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  return (
    <section id="hero" className="jumbotron">
      <Container>
        <Row className="align-items-center">
          <Col lg={6}>
            <Fade left={isDesktop} bottom={isMobile} duration={500} delay={250} distance="30px">
              <h1 className="hero-title">
                {title || "Hey, I'm"} <span className="text-color-main">{name || 'Your Name'}</span>
                <br />
                <span className="hero-subtitle">
                  a{' '}
                  <Typewriter
                    options={{
                      strings: ['future engineer', 'developer', 'creator'],
                      autoStart: true,
                      loop: true,
                    }}
                  />
                </span>
              </h1>
            </Fade>
            <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={1000} distance="30px">
              <p className="hero-cta">
                <span className="cta-btn cta-btn--hero">
                  <Link to="about" smooth duration={1000}>
                    Learn more
                  </Link>
                </span>
              </p>
            </Fade>
          </Col>
          <Col lg={6}>
            <img className="hero-img" src={dp} alt="Logo" />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Header;
