
import React from "react";
import { Container, Row, Col } from 'react-bootstrap';

import Title from '../Title/Title';


const Work = () => {

  return (
    <section id="work">
    <div >
      <Title title="Experience" />
      <div className="experiences">
        <Container >
        <Row>
            <Col md={4}>
              <h4 className="xp-title">Zaio</h4>
              <p>Dec 2020 - Present</p>
            </Col>
            <Col md={8}>
              <p><strong>Front-End Developer</strong></p>
              <p>
                <span>Led and implemented the website B2C and B2B landing pages from concept through the deployment of Zaio - an online e-learning coding platform</span>
              </p>
            </Col>
          </Row>
          <br/>
          <Row>
            <Col md={4}>
              <h4 className="xp-title">Brightmind</h4>
              <p>Jan 2021- March 2021</p>
            </Col>
            <Col md={8}>
              <p><strong>Lead Front-End Developer</strong></p>
              <p>
                <span>Led and implemented the website landing page in ReactJS from concept through the deployment of Brightmind - an online learning app that provides an engaging & accessible educational experience for K-12 students in Africa.</span>
              </p>
            </Col>
          </Row>

          <Row>
            <Col md={4}>
              <h4 className="xp-title">University of Cape Town</h4>
              <p>Sep 2019 - Sept 2020</p>
            </Col>
            <Col md={8}>
              <p><strong>Senior Mentor</strong></p>
              <p>
                <span>Key responsibilities include helping with the personal development of students, maintaining confidentiality and ensuring first time entering university students feel at home providing them with all the resources needed.</span>
              </p>
            </Col>
          </Row>

          <Row>
            <Col md={4}>
              <h4 className="xp-title">Quillo</h4>
              <p>Jun 2019 - Jul 2020</p>
            </Col>
            <Col md={8}>
              <p><strong>Front-end Developer</strong></p>
              <p>
                <span>UI/UX redesign and implementation of new features. Technologies used: Angular, Typescript, HTML5 & CSS3.

                  Redesigned and simplified the overall user interface such as the home page, while preserving all functionality.
                  Exposed to new technologies such as angular, typescript and GIT.
                </span>
              </p>
            </Col>
          </Row>
        </Container>
      </div>


    </div>
    </section>
  );

}
export default Work;
