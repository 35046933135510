
import React, { Component } from "react";

import "../../style/components/navbar.css";
import { Link } from 'react-scroll';
import { FaBars, FaTimes } from 'react-icons/fa';
import logo from '../../images/logo.png'
import { MenuItems } from "./MenuItems";
class Navbar extends Component {
    state = { clicked: false };

    handleClick = () => {
        this.setState({ clicked: !this.state.clicked });
    };

    render() {
        return (
            <nav >
                <div className="NavbarItems">

                    <div className="navbar-logo">
                        <img className="zaio-logo" src={logo} alt=""></img>
                    </div>

                    <div className="menu-icon" onClick={this.handleClick}>
                        {this.state.clicked ? <FaTimes /> : <FaBars />}

                    </div>
                    <ul className={this.state.clicked ? "nav-menu active" : "nav-menu"}>
                        {MenuItems.map((item, index) => {
                            return (
                                <li key={index}>
                                    <Link className={item.cName} to={item.url} smooth duration={1000}>
                                        {item.title}
                                    </Link>     
                                    
                                </li>
                                
                            );
                        })}
                        <li><a className="nav-links zfb" href="https://drive.google.com/file/d/12P5Q3gYwBJdbZ48_aJd1xiYmEi4My65G/view?usp=sharing" target="_blank">Resume/CV</a></li>
                    </ul>
                </div>
            </nav>
        );
    }
}
export default Navbar;
