export const MenuItems = [
    {
        title: 'About',
        url: 'about',
        cName: 'nav-links',
        target:""
    },
    {
        title: 'Experience',
        url: 'work',
        cName: 'nav-links',
        target:""
    },
    {
        title: 'Projects',
        url: 'projects',
        cName: 'nav-links',
        target:""
    },
    {
        title: 'Contact',
        url: 'contact',
        cName: 'nav-links',
        target:""
    },
    
    
    

]